import { forwardRef, useRef, useImperativeHandle, useMemo, useEffect } from 'react'
import Lottie from 'lottie-react'

//* Style
import LottieAnimationStyle from './style'

const LottieAnimation = forwardRef(({ animData, loop, autoplay, isPlay, isPaused, isReverse, className, onCompletePause }, ref) => {
	//! Refs
	const contRef = useRef()
	const lottieRef = useRef()

	useImperativeHandle(ref, () => contRef.current, [])

	useEffect(() => {
		if (isPlay) {
			lottieRef.current.setDirection(1)
			lottieRef.current.play()
		}

		if (isPaused) {
			lottieRef.current.pause()
		}

		if (isReverse) {
			lottieRef.current.setDirection(-1)
			lottieRef.current.play()
		}
	}, [isPlay, isPaused, isReverse, lottieRef])

	//! Default options
	const defaultOptions = useMemo(
		() => ({
			loop: loop,
			autoplay: autoplay,
			animationData: animData,
			rendererSettings: {
				preserveAspectRatio: 'xMidYMid slice',
			},
		}),
		[animData, loop, autoplay]
	)

	return (
		<LottieAnimationStyle
			ref={contRef}
			className={`lottie-container ${className || ''}`}
		>
			<Lottie
				width={'auto'}
				height={'100%'}
				lottieRef={lottieRef}
				className={`lottie-wrapper`}
				onLoopComplete={() => {
					if (onCompletePause) {
						lottieRef.current.pause()
					}
				}}
				{...defaultOptions}
			/>
		</LottieAnimationStyle>
	)
})

export default LottieAnimation
