import React from 'react';
import App from 'next/app';
import dynamic from 'next/dynamic';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

gsap.config({
	force3D: true,
});

if (typeof window !== 'undefined') {
	gsap.registerPlugin(ScrollTrigger);
}

//* Providers
import { UIProvider, DataProvider, LanguageProvider } from 'context';

//* Helpers
import { getData, getPageParams, config } from 'helpers';

//* Components
import Popup from 'components/common/Popup';
import AppHeader from 'components/common/AppHeader';
import AgeGate from 'components/common/AgeGate';

const AppFooter = dynamic(() => import('components/common/AppFooter'), { ssr: false });

//* Styles
import 'swiper/css';
import 'styles/icons/style.css';
import 'styles/antd.normalize.css';

class MyApp extends App {
	static async getInitialProps({ Component, ctx, router, ...props }) {
		const isMobileView = (ctx.req ? ctx.req.headers['user-agent'] : navigator.userAgent).match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i);

		//! Checking If First Request or Not
		if (typeof window === 'undefined') {
			//! Default States
			const newState = {
				pages: {},
				global: {},
			};

			//! Getting Page Data
			const data = await getData({ lang: ctx.locale, state: newState, router: ctx, getPageParams: true });

			return {
				pageProps: {
					...data,
					browser: false,
					isMobileView,
				},
			};
		} else {
			return {
				pageProps: {
					pageParams: getPageParams({ lang: ctx.locale, router: ctx }),
					browser: true,
					isMobileView,
				},
			};
		}
	}

	render() {
		const { Component, pageProps, router } = this.props;

		return (
			<>
				<LanguageProvider>
					<UIProvider>
						<DataProvider newData={pageProps}>
							{router.pathname !== '/404' && <AppHeader transparentHeader={pageProps.pageParams?.transparentHeader} />}

							<Component
								{...pageProps}
								config={config.routes}
								selectedLang={router.locale}
							/>

							{router.pathname !== '/404' && <AgeGate />}

							<Popup />

							{router.pathname !== '/404' && <AppFooter config={config.routes} />}
						</DataProvider>
					</UIProvider>
				</LanguageProvider>
			</>
		);
	}
}

export default MyApp;
