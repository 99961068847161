import styled from 'styled-components'

const ButtonStyle = styled.div`
	//! Default */
	--animBtnBorderRadius: var(--sp7x);
	--animBtnPaddingH: var(--sp4x);
	--animBtnPaddingV: var(--sp2x);
	--animBtnIconBgSize: var(--sp6x);
	--animBtnIconSize: var(--sp4x);
	--animBtnIconRightPos: var(--sp1x);
	--animBorderWidth: 1px;

	//! With background & transparent color
	--withBCPadTB: var(--sp2x);
	--withBCPadLR: var(--sp4x);

	//! With Lottie
	--arrowLottieSize: var(--sp4x);
	--arrowLottieDistance: var(--sp2x);

	&.anim-btn {
		position: relative;
		display: inline-flex;
        height: calc(var(--animBtnIconBgSize) + (var(--animBtnIconRightPos) * 2) + var(--animBorderWidth) * 2);
        backface-visibility: hidden;

		.anim-btn-inner-cont {
			position: relative;
			display: inline-flex;
			padding: var(--animBtnPaddingV) calc(var(--animBtnPaddingH) + var(--animBtnIconBgSize) + var(--animBtnIconRightPos)) var(--animBtnPaddingV) var(--animBtnPaddingH);
			color: var(--primaryColor9);
			text-transform: uppercase;
			white-space: nowrap;
			opacity: 0;
			z-index: 2;

			&.fake {
				pointer-events: none;
			}
		}

		.anim-btn-cont {
			display: flex;
			align-items: center;
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			width: calc(var(--animBtnIconBgSize) + (var(--animBtnIconRightPos) * 2) + 2px);
			height: calc(var(--animBtnIconBgSize) + (var(--animBtnIconRightPos) * 2) + var(--animBorderWidth) * 2);
			border: var(--animBorderWidth) solid var(--primaryColor9);
			border-radius: var(--animBtnBorderRadius);
			transform: scale(0);
			backface-visibility: hidden;
			cursor: pointer;

			.anim-btn-icon-cont {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				position: absolute;
				top: 50%;
				right: var(--animBtnIconRightPos);
				left: var(--animBtnIconRightPos);
				transform: scale(1) translate(0, -50%);
				/* width: var(--animBtnIconBgSize); */
				height: var(--animBtnIconBgSize);
				font-size: var(--animBtnIconSize);
				color: var(--primaryColor16);
				background-color: var(--primaryColor9);
				border-radius: var(--animBtnBorderRadius);
				padding: calc((var(--animBtnIconBgSize) - var(--animBtnIconSize)) / 2);
				z-index: 3;
                transition: transform var(--trTime);
                transform-origin: top;
                backface-visibility: hidden;

				i {
					transform: rotate(-90deg);
					transition: 0.3s;
                    backface-visibility: hidden;
				}
			}

			&:hover {
                .anim-btn-icon-cont {
                    transform: scale(1.15) translate(0, -50%);
                }
			}
		}
	}

	&.with-bg-color {
		position: relative;
		display: inline-block;
		padding: var(--withBCPadTB) var(--withBCPadLR);
		border-radius: calc(var(--sp15x) / 2);
		pointer-events: all;
		text-align: center;
		white-space: nowrap;
		isolation: isolate;
		cursor: pointer;

		a,
		button {
			z-index: 3;
			font-family: var(--notoSansFont);
			font-weight: 500;
			color: var(--primaryColor1);
			text-transform: uppercase;
			cursor: pointer;
		}

		.fake-bg-color-for-anim {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background-color: var(--primaryColor9);
			isolation: isolate;
			pointer-events: none;
			border-radius: calc(var(--sp15x) / 2);
			z-index: -1;
			transition: transform var(--trTime) ease-out;
		}

		@media (hover: hover) {
			&:hover {
				.fake-bg-color-for-anim {
					transform: scaleX(1.1);
				}
			}
		}
	}

	&.with-bg-transparent {
		position: relative;
		display: inline-block;
		padding: var(--withBCPadTB) var(--withBCPadLR);
		border-radius: calc(var(--sp15x) / 2);
		pointer-events: all;
		text-align: center;
		white-space: nowrap;
		isolation: isolate;
		cursor: pointer;

		a,
		button {
			z-index: 3;
			font-family: var(--notoSansFont);
			font-weight: 500;
			color: var(--primaryColor9);
			text-transform: uppercase;
			cursor: pointer;
		}

		.fake-bg-transparent-for-anim {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background-color: transparent;
			isolation: isolate;
			pointer-events: none;
			border-radius: calc(var(--sp15x) / 2);
			border: 1px solid var(--primaryColor9);
			z-index: -1;
			transition: transform var(--trTime) ease-out;
		}

		@media (hover: hover) {
			&:hover {
				.fake-bg-transparent-for-anim {
					transform: scaleX(1.1);
				}
			}
		}
	}

	&.with-lottie {
		display: inline-flex;

		a,
		button {
			display: inline-flex;
			align-items: center;
			white-space: nowrap;
			font-family: var(--minion3Font);
			font-weight: 500;
			color: var(--primaryColor9);
			text-transform: uppercase;
			cursor: pointer;
		}

		.lottie-container {
			width: auto;
			display: flex;
			flex-direction: column;
			justify-content: center;
			margin-right: var(--arrowLottieDistance);

			.lottie-wrapper {
				height: var(--arrowLottieSize);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
        //! Default */
        --animBtnBorderRadius: var(--sp7x);
        --animBtnPaddingH: var(--sp4x);
        --animBtnPaddingV: var(--sp2x);
        --animBtnIconBgSize: var(--sp5x);
        --animBtnIconSize: var(--sp3x);
        
		//! With background & transparent color
		--withBCPadTB: var(--sp1-5x);
		--withBCPadLR: var(--sp4x);

		//! With Lottie
		--arrowLottieSize: var(--sp3x);
		--arrowLottieDistance: var(--sp1x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
        //! Default */
        --animBtnBorderRadius: var(--sp7x);
        --animBtnPaddingH: var(--sp2x);
        --animBtnPaddingV: var(--sp1-5x);
        --animBtnIconBgSize: var(--sp4x);
        --animBtnIconSize: var(--sp2-5x);

		//! With background & transparent color
		--withBCPadTB: var(--sp1-5x);
		--withBCPadLR: var(--sp3x);

		//! With Lottie
		--arrowLottieSize: var(--sp3x);
		--arrowLottieDistance: var(--sp1x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
        //! Default */
        --animBtnBorderRadius: var(--sp7x);
        --animBtnPaddingH: var(--sp2x);
        --animBtnPaddingV: var(--sp1-5x);
        --animBtnIconBgSize: var(--sp4x);
        --animBtnIconSize: var(--sp2-5x);

		//! With background & transparent color
		--withBCPadTB: var(--sp1-5x);
		--withBCPadLR: var(--sp3x);

		//! With Lottie
		--arrowLottieSize: var(--sp2-5x);
		--arrowLottieDistance: var(--sp1-5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
        //! Default */
        --animBtnBorderRadius: var(--sp7x);
        --animBtnPaddingH: var(--sp2x);
        --animBtnPaddingV: var(--sp1-5x);
        --animBtnIconBgSize: var(--sp4x);
        --animBtnIconSize: var(--sp2-5x);
        
		//! With background & transparent color
		--withBCPadTB: var(--sp1-5x);
		--withBCPadLR: var(--sp3x);

		//! With Lottie
		--arrowLottieSize: var(--sp2-5x);
		--arrowLottieDistance: var(--sp1-5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
        //! Default */
        --animBtnBorderRadius: var(--sp7x);
        --animBtnPaddingH: var(--sp2x);
        --animBtnPaddingV: var(--sp1-5x);
        --animBtnIconBgSize: var(--sp4x);
        --animBtnIconSize: var(--sp2-5x);

		//! With background & transparent color
		--withBCPadTB: var(--sp1-5x);
		--withBCPadLR: var(--sp3x);

		//! With Lottie
		--arrowLottieSize: var(--sp2-5x);
		--arrowLottieDistance: var(--sp1-5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
        //! Default */
        --animBtnBorderRadius: var(--sp7x);
        --animBtnPaddingH: var(--sp2x);
        --animBtnPaddingV: var(--sp1-5x);
        --animBtnIconBgSize: var(--sp4x);
        --animBtnIconSize: var(--sp2-5x);

		//! With background & transparent color
		--withBCPadTB: var(--sp1-5x);
		--withBCPadLR: var(--sp3x);

		//! With Lottie
		--arrowLottieSize: var(--sp2-5x);
		--arrowLottieDistance: var(--sp1-5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
        //! Default */
        --animBtnBorderRadius: var(--sp7x);
        --animBtnPaddingH: var(--sp2x);
        --animBtnPaddingV: var(--sp1-5x);
        --animBtnIconBgSize: var(--sp4x);
        --animBtnIconSize: var(--sp2-5x);
        
		//! With background & transparent color
		--withBCPadTB: var(--sp1-5x);
		--withBCPadLR: var(--sp3x);

		//! With Lottie
		--arrowLottieSize: var(--sp2-5x);
		--arrowLottieDistance: var(--sp1-5x);
	}
`
export default ButtonStyle
