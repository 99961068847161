import { createGlobalStyle } from 'styled-components'

const FormStyle = createGlobalStyle`
    :root {
        --inpPadB: var(--sp2-5x);
        --errorMTop: var(--sp2x);
    }

    .ant-form {
        .ant-form-item {
            .ant-input {
                padding: 0;
                border: none;
                font-weight: 400;
                border-radius: 0;
                transition: none;
                font-size: var(--p3);
                font-family: var(--notoSansFont);
                color: var(--primaryColor9);
                background-color: transparent;
                padding-bottom: var(--inpPadB);
                line-height: var(--lineHeightL);
                border-bottom: 1px solid var(--primaryColor9);

                &::placeholder {
                    font-size: var(--p3);
                    color: var(--secondaryColor3);
                    line-height: var(--lineHeightL);
                    font-family: var(--notoSansFont);
                    font-weight: 400;
                }
            }

            //! Focus
            .ant-input-affix-wrapper {
                &.ant-input-affix-wrapper-focused {
                    color: var(--secondaryColor3);
                }
            }

            .ant-input-suffix,
            .ant-input-textarea-suffix {
                display: none;
            }

            //! Error message styles
            .ant-form-item-explain {
                font-size: var(--p5);
                font-family: var(--notoSansFont);
                font-weight: 400;
                line-height: var(--lineHeightL);

                .ant-form-item-explain-error {
                    transform: translateY(var(--errorMTop));
                }
            }
        }

        //! Error styles
        .ant-form-item-has-error {
            .ant-input {
                color: var(--errorColor);
                border-bottom: 1px solid var(--errorColor);

                &::placeholder {
                    color: var(--errorColor);
                }
            }

            .ant-input-status-error {
                &.ant-input {
                    background-color: transparent !important;
                }
            }
        }
    }

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
        :root {
            --inpPadB: var(--sp2x);
            --errorMTop: var(--sp1-5x);
        }
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
        :root {
            --inpPadB: var(--sp2x);
            --errorMTop: var(--sp1-5x);
        }
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
        :root {
            --inpPadB: var(--sp2x);
            --errorMTop: var(--sp1-5x);
        }
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
        :root {
            --inpPadB: var(--sp2x);
            --errorMTop: var(--sp1x);
        }
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
        :root {
            --inpPadB: var(--sp2x);
            --errorMTop: var(--sp1x);
        }
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
        :root {
            --inpPadB: var(--sp2x);
            --errorMTop: var(--sp1x);
        }
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
        :root {
            --inpPadB: var(--sp2x);
            --errorMTop: var(--sp1x);
        }
	}
`
export default FormStyle
