import styled from 'styled-components'

const BurgerMenuStyle = styled.section`
	--bmBottomContPadT: var(--sp16x);
	--bmBottomContPadB: var(--sp6x);
	--bmItemsMTop: var(--sp2x);
	--bmSocialIconsWrapMTop: var(--sp8x);
	--iconsDistance: var(--sp5x);

	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 4;
	overflow: hidden;
	pointer-events: none;

	.burger-menu-inner {
		transition: transform var(--trTime);
		transform: translate3d(100%, 0, 0);
		height: 100%;
	}

	&.active {
		pointer-events: all;

		.burger-menu-inner {
			transform: translate3d(0, 0, 0);
		}
	}

	.container {
		height: 100%;
	}

	.burger-menu-content-wrapper {
		padding-top: var(--bmBottomContPadT);
		padding-bottom: var(--bmBottomContPadB);

		.bm-items-wrap {
			display: flex;
			flex-direction: column;

			.burger-menu-item {
				&:not(:first-child) {
					margin-top: var(--bmItemsMTop);
				}
			}

			.link-item {
				&:hover {
					color: var(--primaryColor9);
				}
			}
		}

		.bm-social-icons-wrap {
			margin-top: var(--bmSocialIconsWrapMTop);
			display: flex;
			align-items: center;
			justify-content: center;

			.icon-wrap {
				margin: 0 calc(var(--iconsDistance) / 2);

				i {
					line-height: 1 !important;

					&:before {
						color: var(--primaryColor9);
					}

					@media (hover: hover) {
						&:hover {
							&:before {
								color: var(--primaryColor11);
							}
						}
					}
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--bmBottomContPadB: var(--sp6x);
		--bmItemsMTop: var(--sp2x);
		--bmSocialIconsWrapMTop: var(--sp6x);
		--iconsDistance: var(--sp5x);
		--bmBottomContPadT: var(--sp15x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--bmBottomContPadB: var(--sp5x);
		--bmItemsMTop: var(--sp2x);
		--bmSocialIconsWrapMTop: var(--sp6x);
		--bmBottomContPadT: var(--sp15x);
		--iconsDistance: var(--sp5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--bmBottomContPadB: var(--sp5x);
		--bmItemsMTop: var(--sp2-5x);
		--bmSocialIconsWrapMTop: var(--sp5x);
		--bmBottomContPadT: var(--sp14x);
		--iconsDistance: var(--sp5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--bmBottomContPadB: var(--sp10x);
		--bmItemsMTop: var(--sp3x);
		--bmSocialIconsWrapMTop: var(--sp15x);
		--bmBottomContPadT: var(--sp19x);
		--iconsDistance: var(--sp5x);

		.burger-scroll-wrapper {
			overflow-y: scroll;
			height: calc(100vh - var(--bmBottomContPadT) - var(--bmBottomContPadB));
			scrollbar-width: none; /* Firefox */
			-ms-overflow-style: none; /* IE and Edge */

			&::-webkit-scrollbar {
				display: none;
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--bmBottomContPadB: var(--sp10x);
		--bmItemsMTop: var(--sp2-5x);
		--bmSocialIconsWrapMTop: var(--sp11x);
		--bmBottomContPadT: var(--sp18x);
		--iconsDistance: var(--sp4x);

		.burger-scroll-wrapper {
			overflow-y: scroll;
			height: calc(100vh - var(--bmBottomContPadT) - var(--bmBottomContPadB));
			scrollbar-width: none; /* Firefox */
			-ms-overflow-style: none; /* IE and Edge */

			&::-webkit-scrollbar {
				display: none;
			}
		}
	}
`

export default BurgerMenuStyle
