import { useRef, useState, useMemo, useCallback, useEffect } from 'react'
import gsap from 'gsap'
import Cookie from 'js-cookie'

//* HOCs
import withUIContext from 'context/consumerHOC/UIConsumer'
import withDataContext from 'context/consumerHOC/DataConsumer'
import withLanguageContext from 'context/consumerHOC/LanguageConsumer'

//* Components
import Text from 'components/common/Text'
import Logo from 'components/common/Logo'
import Button from 'components/common/Button'
import Animate from 'components/common/Animate'
import AgeGateRejection from 'components/common/AgeGateRejection'

//* Style
import AgeGateStyle from './style'

const AgeGate = ({ setIsGatesActive, global, selectedLang, translate }) => {
	//! Global Data
	const globalData = useMemo(() => global?.[selectedLang], [global, selectedLang])

	//! States
	const [active, setActive] = useState(false)
	const [isAnimActive, setIsAnimActive] = useState(false)
	const [gates, setGates] = useState({ askAge: true, rejection: false })

	//! Refs
	const ageGateRef = useRef()
	const topOvalRef = useRef()
	const contentWrap = useRef()
	const buttonsWrap = useRef()
	const bottomOvalRef = useRef()

	const gatesContentAnimation = useMemo(
		() =>
			isAnimActive && (
				<Animate
					duration={0.5}
					params={{ opacity: [contentWrap] }}
				/>
			),
		[contentWrap, isAnimActive]
	)

	const gatesButtonsAnimation = useMemo(
		() =>
			isAnimActive && (
				<Animate
					duration={0.5}
					params={{ opacity: [buttonsWrap] }}
				/>
			),
		[buttonsWrap, isAnimActive]
	)

	//! Did Mount
	useEffect(() => {
		const cpCookie = Cookie.get('cp')

		if (!cpCookie) {
			setActive(true)
		} else {
			setIsAnimActive(true)

			setTimeout(() => {
				setGates({ askAge: false, rejection: false })
				setIsGatesActive(false)
			}, 900)
		}
	}, [])

	const handleGatesClose = useCallback(() => {
		Cookie.set('cp', true)
		setIsAnimActive(true)

		setTimeout(() => {
			setGates({ askAge: false, rejection: false })
			setIsGatesActive(false)
		}, 800)
	}, [])

	useEffect(() => {
		if (isAnimActive) {
			gsap.fromTo(ageGateRef.current, { opacity: 1 }, { opacity: 0, delay: 0.5, duration: 0.1 })

			gsap.to(topOvalRef.current, { borderRadius: '0', delay: 0.2, duration: 1.3, y: '-100%', force3D: true })

			gsap.to(bottomOvalRef.current, { borderRadius: '0', delay: 0.2, duration: 1.3, y: '100%', force3D: true })
		}
	}, [ageGateRef, topOvalRef, bottomOvalRef, isAnimActive])

	return gates.askAge ? (
		<AgeGateStyle ref={ageGateRef}>
			<div className={`age-gate-relative-wrapper`}>
				<div
					ref={topOvalRef}
					className={'curve-wrap top'}
				/>

				<div className={`empty`} />

				<Logo className={'age-gate-logo'} />

				{active && (
					<>
						{gatesContentAnimation}
						<div
							className='content-wrap'
							ref={contentWrap}
						>
							<Text
								className={'h5 minion3-medium primary-color9 uppercase title'}
								text={globalData?.gate.title}
							/>
							<Text
								className={'p-2 notoSans-regular primary-color9 description'}
								text={globalData?.gate.text}
							/>
						</div>
					</>
				)}

				{active && (
					<>
						{gatesButtonsAnimation}
						<div
							className={'buttons-wrap'}
							ref={buttonsWrap}
						>
							<Button
								className={'first-button'}
								btnType={'with-bg-transparent'}
								text={translate('no')}
								onClick={() => setGates({ askAge: false, rejection: true })}
							/>

							<Button
								text={translate('yes')}
								btnType={'with-bg-color'}
								onClick={handleGatesClose}
							/>
						</div>
					</>
				)}

				<div
					ref={bottomOvalRef}
					className={'curve-wrap bottom'}
				/>
			</div>
		</AgeGateStyle>
	) : gates.rejection ? (
		<AgeGateRejection />
	) : null
}

export default withLanguageContext(withDataContext(withUIContext(AgeGate, ['setIsGatesActive']), ['global']), ['selectedLang', 'translate'])
