import { memo as Memo, useEffect, useCallback, useId } from 'react'

//* HOC's
import withUIContext from 'context/consumerHOC/UIConsumer'

//* GSAP
import { gsap, SteppedEase } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

//* Helpers
import { letterSpacingAnimation } from 'helpers'

const Animate = Memo(({ params, startPoint, dependency, preloader, startX, startY, stagger, inf, duration, initialPath, delay, ...props }) => {
    const id = useId()

    const getDefaultParams = useCallback(
        (items, index) => ({
            ease: 'power4.out',
            scrollTrigger: {
                trigger: items,
                start: startPoint || '-=100% bottom',
                toggleActions: 'play none none none',
                delay: 0.1,
                id: `${index}-${id}`,
                overwrite: true,
                // markers: true,
            },
        }),
        [startPoint, inf]
    )

    const animateFromBottomToTop = useCallback(
        (items, index) => {
            gsap.fromTo(
                items,
                {
                    y: startY || '100%',
                },
                {
                    ...getDefaultParams(items, index),
                    y: '0',
                    stagger: stagger || 0.5,
                    duration: duration || 1,
                }
            )
        },
        [startPoint, startY, stagger, duration]
    )

    const animateFromRightToLeft = useCallback(
        (items, index) => {
            gsap.fromTo(
                items,
                {
                    x: startX || '100%',
                },
                {
                    ...getDefaultParams(items, index),
                    x: '0',
                    stagger: 0.1,
                    duration: duration || 2.5,
                }
            )
        },
        [startPoint, startX, duration]
    )

    const animateOpacity = useCallback(
        (items, index) => {
            gsap.fromTo(
                items,
                {
                    opacity: 1,
                },
                {
                    ...getDefaultParams(items, index),
                    opacity: 0,
                    delay: delay || 0,
                    stagger: stagger || 0.3,
                    duration: duration || 3,
                }
            )
        },
        [startPoint, duration]
    )

    const animateTyping = useCallback(
        (items, index) => {
            gsap.fromTo(
                items,
                {
                    x: '-5%',
                },
                {
                    ...getDefaultParams(items, index),
                    x: '100%',
                    duration: 1.5,
                    ease: SteppedEase.config(15),
                }
            )
        },
        [startPoint]
    )

    const animatePath = useCallback(
        (items, index) => {
            gsap.to(items, {
                ...getDefaultParams(items, index),
                attr: { d: 'M 0 0 L 0 0 C 14 0 41 0 53 0 L 53 0 L 53 0' },
                duration: duration || 2,
            })
        },
        [initialPath, duration]
    )

    const letterSpacing = useCallback((items, index) => {
        gsap.fromTo(items, { letterSpacing: '1.8rem', opacity: 0 }, { ...getDefaultParams(items, index), duration: 1.5, letterSpacing: '1px', opacity: 1 })
    }, [])

    useEffect(() => {
        if (params !== null) {
            Object.keys(params).forEach((type, i) => {
                const items = params[type].reduce((arr, item) => (Array.isArray(item.current) ? [...arr, ...item.current] : [...arr, item.current]), [])

                if (items.length) {
                    gsap.killTweensOf(items)
                    // const anim = ScrollTrigger.getById(`${i}-${id}`)

                    // if (!anim) {
                    if (type === 'bottomToTop') {
                        animateFromBottomToTop(items, i)
                    } else if (type === 'opacity') {
                        animateOpacity(items, i)
                    } else if (type === 'rightToLeft') {
                        animateFromRightToLeft(items, i)
                    } else if (type === 'typing') {
                        animateTyping(items, i)
                    } else if (type === 'animatePath') {
                        animatePath(items, i)
                    } else if (type === 'letterSpacing') {
                        letterSpacing(items, i)
                    }
                    // }
                    ScrollTrigger.refresh()
                }
            })
        }

        return () => {
            Object.keys(params).forEach((type, i) => {
                const anim = ScrollTrigger.getById(`${i}-${id}`)
                if (anim) {
                    anim?.kill()
                }
            })

            ScrollTrigger.refresh()
        }
    }, [params, preloader, dependency])

    // useEffect(() => {
    //     return () => {
    //         Object.keys(params).forEach((type, i) => {
    //             ScrollTrigger.getById(`${i}-${id}`)?.kill()
    //         })
    //     }
    // }, [dependency, params])

    return props.children
})

export default withUIContext(Animate, ['preloader'])
