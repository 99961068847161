import styled from 'styled-components'

const LottieAnimationStyle = styled.div`
	&.lottie-container {
		position: relative;

		svg {
			width: auto !important;
			z-index: 1;
		}
	}
`
export default LottieAnimationStyle
