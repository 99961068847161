import { createGlobalStyle, css } from 'styled-components';

function columnGenerator(suffix) {
	let style = '';
	for (let i = 1; i <= 12; i++) {
		style += suffix ? `.col-${suffix}-${i}` : `.col-${i}`;
		style += `{ width: ${(100 / 12) * i}% } `;
	}

	return style;
}

const Typography = createGlobalStyle`${css`
	* {
		outline: none;
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		scrollbar-width: none;
		-webkit-appearance: none;
		-webkit-font-smoothing: antialiased;
		-webkit-text-size-adjust: 100%;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important; //! Touch highlight color
	}

	body {
		width: 100%;
		min-height: 100vh;
		margin: 0;
		padding: 0;
		background-color: var(--bodyColor);
		${(props) => (props.isBurgerMenuOpen || props.popupIsOpen || props.isGatesActive ? 'overflow: hidden;' : ' overflow-x: hidden;')}
		-webkit-overflow-scrolling: touch;

		main {
			display: flex;
			flex-direction: column;
			min-height: 100vh;
			z-index: 1;
			transition: margin-top 1s, transform var(--trTime);
			transform: ${(props) => (props.isBurgerMenuOpen ? 'translate3d(-100%, 0, 0)' : 'translate3d(0, 0, 0)')};

			&:not(.page-404) {
				opacity: ${(props) => (props.isGatesActive ? 0 : 1)};
			}
		}

		#__next {
			min-height: 100vh;
		}

		&::-webkit-scrollbar {
			display: none;
		}

		&.hidden {
			overflow: hidden;
			max-height: 100vh;
		}
	}

	ul,
	fieldset {
		margin: 0;
		padding: 0;
		list-style: none;
		border: none;
	}

	p {
		margin-block-start: 0;
		margin-block-end: 0;
	}

	button {
		padding: 0;
		border-width: 0;
		border-color: inherit;
		background-color: inherit;
	}

	textarea {
		/* //! for all text* area elements */
		resize: none;
		scrollbar-width: none; /* Firefox */
		-ms-overflow-style: none; /* IE and Edge */

		&::-webkit-scrollbar {
			display: none;
		}
	}

	input[type='search']::-webkit-search-decoration,
	input[type='search']::-webkit-search-cancel-button,
	input[type='search']::-webkit-search-results-button,
	input[type='search']::-webkit-search-results-decoration {
		-webkit-appearance: none;
	}

	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	input[type='number'] {
		-moz-appearance: textfield;
	}

	input:-internal-autofill-selected {
		background-color: transparent !important;
		box-shadow: inset 1000px 1000px var(--primaryColor1);
	}

	input:focus::placeholder {
		color: transparent;
	}

	input {
		outline: none;
	}

	input:-webkit-autofill {
		-webkit-text-fill-color: var(--primaryColor9) !important;
		box-shadow: inset 0 0 0 1000px var(--primaryColor1) !important;
	}

	//! Change the transparent to any color
	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	textarea:-webkit-autofill,
	textarea:-webkit-autofill:hover,
	textarea:-webkit-autofill:focus,
	select:-webkit-autofill,
	select:-webkit-autofill:hover,
	select:-webkit-autofill:focus {
		color: var(--primaryColor1);
		border: 1px solid var(--primaryColor1);
		background-color: var(--bodyColor);
		transition: background-color 5000s ease-in-out 0s;
		box-shadow: 0 0 0 1000px var(--bodyColor) inset;
		-webkit-text-fill-color: var(--primaryColor1);
	}

	@keyframes autofill {
		to {
			background: var(--bodyColor);
		}
	}

	a {
		color: unset;
		text-decoration: none;
		-webkit-tap-highlight-color: transparent;
	}

	//! Font sizes
	.h1,
	h1 {
		font-size: var(--h1);
		line-height: var(--lineHeightH1);
	}

	.h2,
	h2 {
		font-size: var(--h2);
		line-height: var(--lineHeightH2);
	}

	.h3,
	h3 {
		font-size: var(--h3);
		line-height: var(--lineHeightH3);
	}

	.h4,
	h4 {
		font-size: var(--h4);
		line-height: var(--lineHeightH4);
	}

	.h5,
	h5 {
		font-size: var(--h5);
		line-height: var(--lineHeightH5);
	}

	.h6,
	h6 {
		font-size: var(--h6);
		line-height: var(--lineHeightH6);
	}

	.p,
	p {
		font-size: var(--p);
		line-height: var(--lineHeightP1);
		--p: var(--p1);

		&.p1 {
			--p: var(--p1);
		}

		&.p2 {
			--p: var(--p2);
			--lineHeightP1: var(--lineHeightP2);
		}

		&.p3 {
			--p: var(--p3);
			--lineHeightP1: var(--lineHeightP3);
		}

		&.p4 {
			--p: var(--p4);
			--lineHeightP1: var(--lineHeightP4);
		}

		&.p5 {
			--p: var(--p5);
			--lineHeightP1: var(--lineHeightP5);
		}
	}

	//! Global error wrapper
	.global-error-wrapper {
		border: 2px solid var(--errorColor);
		border-radius: var(--sp1-5x);
		background-color: rgba(222, 85, 78, 0.05);
	}

	//! Row
	.row {
		display: flex;
		flex-wrap: wrap;
		margin: 0 calc(calc(var(--colPadding) / 2) * -1);

		> * {
			width: 100%;
			padding: 0 calc(var(--colPadding) / 2);
		}

		.col {
			flex: 1 1;
		}

		${columnGenerator()};

		@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeXLMin}) {
			${columnGenerator('dl')};
		}

		@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeLMin}) {
			${columnGenerator('dm')};
		}

		@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeMMin}) {
			${columnGenerator('ds')};
		}

		@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeSMin}) {
			${columnGenerator('dxs')};
		}

		@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeXSMin}) {
			${columnGenerator('t')};
		}

		@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.TabletSizeMin}) {
			${columnGenerator('ts')};
		}

		@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.TabletSizeSMin}) {
			${columnGenerator('m')}
		}
	}

	@keyframes loading {
		0%,
		100% {
			opacity: 0.7;
		}
		50% {
			opacity: 0.5;
		}
	}

	//! Menu items line hover & active
	.hover-line {
		position: relative;

		&:after {
			content: '';
			display: block;
			position: absolute;
			top: 121%;
			left: 0;
			width: 0;
			height: 1px;
			background-color: var(--primaryColor9);
			transition: width var(--trTime) ease;
		}

		@media (hover: hover) {
			&:hover {
				&:after {
					width: 100%;
				}
			}
		}
	}

	.active-line {
		&:before {
			content: '';
			display: block;
			position: absolute;
			top: 121%;
			left: 0;
			width: 100%;
			height: 1px;
			background-color: var(--primaryColor9);
		}
	}
`}`;

export default Typography;
