import { memo as Memo, useMemo, useEffect, useState, useCallback, useRef } from 'react'
import { useRouter } from 'next/router'

//* GSAP
import { gsap } from 'gsap'

//* HOCs
import withUIContext from 'context/consumerHOC/UIConsumer'
import withDataContext from 'context/consumerHOC/DataConsumer'
import withLanguageContext from 'context/consumerHOC/LanguageConsumer'

//* Helper
import config from 'helpers/_config'

//* Components
import LottieAnimation from 'components/common/LottieAnimation'
import CustomLink from 'components/common/CustomLink'
import Container from 'components/common/Container'
import Text from 'components/common/Text'
import Icon from 'components/common/Icon'
import Logo from 'components/common/Logo'
import BurgerMenu from './BurgerMenu'
import LanguageSwitcher from './LanguageSwitcher'

//* Lottie file
import burgerMenuLottie from '../../../lottieFiles/burgerMenu.json'

//* Style
import HeaderStyle from './style'

const AppHeader = Memo(({ transparentHeader, winWidth, toggleBurgerMenu, closeBurgerMenu, global, selectedLang, isBurgerMenuOpen, isGatesActive, eventOrNews }) => {
	//! Global data
	const globalData = useMemo(() => global?.[selectedLang], [global, selectedLang])

	//! Router
	const router = useRouter()

	//! Checker
	const isBurgerMenu = useMemo(() => {
		return winWidth < 1920
	}, [winWidth])

	//! Refs
	const defaultScroll = useRef(0)
	const instagramRef = useRef()
	const headerMenuLeftItemsRef = useRef([])
	const headerMenuRightItemsRef = useRef([])
	const languageSwitcherRef = useRef()
	const burgerMenuAndCloseRef = useRef()

	//! States
	const [scrollDirection, setScrollDirection] = useState(true)
	const [transparent, setTransparent] = useState(true)

	//! Header navigation function
	const handleNavigation = useCallback(() => {
		if (window.scrollY > defaultScroll.current && window.scrollY > 0) {
			setScrollDirection(false)
		} else if (window.scrollY < defaultScroll.current || window.scrollY <= 0) {
			setScrollDirection(true)
		}

		setTransparent(transparentHeader && window.scrollY <= window.innerHeight * 0.05)

		defaultScroll.current = window.scrollY
	}, [transparentHeader, router.asPath, transparent])

	useEffect(() => {
		closeBurgerMenu()
	}, [router.asPath])

	//! Header navigation
	useEffect(() => {
		handleNavigation()
		window.addEventListener('scroll', handleNavigation)

		return () => {
			window.removeEventListener('scroll', handleNavigation)
		}
	}, [handleNavigation, transparentHeader, router.asPath])

	//! Navbar left part
	const navBarLeft = useMemo(() => {
		return Object.entries(config.routes).map((route, ind) => {
			if (route[1].inHeader === 'left') {
				return (
					<CustomLink
						key={ind}
						url={route[1].path}
						className={`navbar-menu-item opacity-zero hover-line ${router.pathname === route[1].path ? `active-line pointer-none` : ``}`}
						ref={(ref) => ref && (headerMenuLeftItemsRef.current[ind] = ref)}
					>
						<Text
							tag={'p'}
							text={route[1].name}
							className={`no-wrap p p4 notoSans-medium primary-color9`}
						/>
					</CustomLink>
				)
			}
		})
	}, [router.pathname])

	//! Navbar right part
	const navBarRight = useMemo(() => {
		return Object.entries(config.routes).map((route, ind) => {
			if (route[1].inHeader === 'right') {
				return (
					<CustomLink
						key={ind}
						url={route[1].path}
						ref={(ref) => ref && (headerMenuRightItemsRef.current[ind] = ref)}
						className={`navbar-menu-item opacity-zero hover-line ${router.pathname === route[1].path ? `active-line pointer-none` : ``}`}
					>
						<Text
							tag={'p'}
							text={route[1].name}
							className={`no-wrap p p4 notoSans-medium primary-color9`}
						/>
					</CustomLink>
				)
			}
		})
	}, [router.pathname])

	useEffect(() => {
		//! Checkers
		const arr = [instagramRef.current, ...headerMenuLeftItemsRef.current, ...headerMenuRightItemsRef.current, languageSwitcherRef.current, burgerMenuAndCloseRef.current]
		const filterArr = arr.filter((el) => typeof el !== 'undefined' && el !== null)

		if (!isGatesActive) {
			gsap.killTweensOf(filterArr)

			gsap.set(filterArr, {
				opacity: 0,
			})

			gsap.to(filterArr, {
				opacity: 1,
				delay: 0.1,
				duration: 1.2,
			})
		}
	}, [isGatesActive, winWidth])

	const getHeaderColorForIndividual = useMemo(() => {
		return router.pathname.includes('/news-and-events/[slug]') && eventOrNews === 'event' ? 'primary-color1-bg' : ''
	}, [router, eventOrNews])

	return (
		<>
			<HeaderStyle className={`header  ${transparent ? 'transparent-header' : ''} ${scrollDirection ? `visible` : ``} ${getHeaderColorForIndividual}`}>
				<Container className={`relative header-container`}>
					<div className={'header-content-wrap'}>
						<div className={`hc-col-wrap left`}>
							{!isBurgerMenu &&
								(globalData?.socials.instagram ? (
									<div
										ref={instagramRef}
										className={`icon-wrapper opacity-zero`}
									>
										<CustomLink
											target={`_blank`}
											rel={`noopener noreferrer`}
											url={globalData.socials.instagram}
											ariaLabel={globalData.socials.instagram}
										>
											<Icon
												className={`p p2`}
												name={`instagram`}
											/>
										</CustomLink>
									</div>
								) : (
									<div className={`empty`} />
								))}

							{isBurgerMenu && (
								<div
									onClick={toggleBurgerMenu}
									ref={burgerMenuAndCloseRef}
									className={`burger-menu-wrapper cursor-pointer opacity-zero`}
								>
									<LottieAnimation
										animData={burgerMenuLottie}
										loop={false}
										autoplay={false}
										isPlay={isBurgerMenuOpen}
										isReverse={!isBurgerMenuOpen}
									/>
								</div>
							)}

							{!isBurgerMenu && <div className={'navbar-menu-wrap left'}>{navBarLeft}</div>}
						</div>

						<div className={'logo-wrapper'}>
							<Logo />
						</div>

						<div className={`hc-col-wrap right`}>
							{!isBurgerMenu ? <div className={'navbar-menu-wrap right'}>{navBarRight}</div> : <div className={`empty`} />}

							<div
								ref={languageSwitcherRef}
								className={`language-switcher-wrapper opacity-zero`}
							>
								<LanguageSwitcher />
							</div>
						</div>
					</div>

					<div className={`header-fake-background ${isBurgerMenuOpen ? `active` : ``}`} />
				</Container>
			</HeaderStyle>

			{isBurgerMenu && <BurgerMenu />}
		</>
	)
})

export default withLanguageContext(withUIContext(withDataContext(AppHeader, ['global']), ['winWidth', 'eventOrNews', 'toggleBurgerMenu', 'closeBurgerMenu', 'isBurgerMenuOpen', 'isGatesActive']), [
	'selectedLang',
])
