import { memo as Memo, useMemo } from 'react'
import { useRouter } from 'next/router'

//* HOCs
import withUIContext from 'context/consumerHOC/UIConsumer'
import withDataContext from 'context/consumerHOC/DataConsumer'
import withLanguageContext from 'context/consumerHOC/LanguageConsumer'

//* Helper
import config from 'helpers/_config'

//* Components
import Container from 'components/common/Container'
import CustomLink from 'components/common/CustomLink'
import Icon from 'components/common/Icon'
import Text from 'components/common/Text'

//* Styles
import BurgerMenuStyle from './style'

const BurgerMenu = Memo(({ isBurgerMenuOpen, global, selectedLang, translate }) => {
	//! Global data
	const globalData = useMemo(() => global?.[selectedLang], [global, selectedLang])

	//! Router
	const router = useRouter()

	//! Burger menu items
	const burgerMenuItems = useMemo(() => {
		return Object.entries(config.routes).map((route, ind) => {
			if (route[1].inBurgerMenu) {
				return (
					<Text
						key={ind}
						tag={`p`}
						className={`uppercase h5 minion3-medium secondary-color2 burger-menu-item`}
					>
						<CustomLink
							url={route[1].path}
							content={translate(route[1].name)}
							className={`${router.pathname === route[1].path ? `pointer-none primary-color9` : ''}`}
						/>
					</Text>
				)
			}
		})
	}, [router.pathname, selectedLang])

	return (
		<BurgerMenuStyle className={`burger-menu ${isBurgerMenuOpen ? `active` : ''}`}>
			<div className='burger-menu-inner primary-color1-bg'>
				<Container>
					<div className={`burger-menu-content-wrapper`}>
						<div className={`burger-scroll-wrapper`}>
							<div className={`bm-items-wrap align-center`}>{burgerMenuItems}</div>

							<div className={`bm-social-icons-wrap`}>
								{globalData && (
									<>
										<div className={'icon-wrap'}>
											<CustomLink
												url={globalData?.socials.facebook}
												ariaLabel={globalData?.socials.facebook}
												rel={'noopener noreferrer'}
												target={'_blank'}
											>
												<Icon
													name={`facebook`}
													className={`h6`}
												/>
											</CustomLink>
										</div>

										<div className={'icon-wrap'}>
											<CustomLink
												target={'_blank'}
												rel={'noopener noreferrer'}
												url={globalData?.socials.instagram}
												ariaLabel={globalData?.socials.instagram}
											>
												<Icon
													name={`instagram`}
													className={`h6`}
												/>
											</CustomLink>
										</div>
									</>
								)}
							</div>
						</div>
					</div>
				</Container>
			</div>
		</BurgerMenuStyle>
	)
})

export default withLanguageContext(withDataContext(withUIContext(BurgerMenu, ['isBurgerMenuOpen']), ['global']), ['selectedLang', 'translate'])
