// import _ from 'lodash'
import Cookie from 'js-cookie'
import gsap from 'gsap'

// export function imagePathToWebP(src) {
// 	const pos = src.lastIndexOf('.'),
// 		image = src.includes('images.ctfassets') ? src + '?fm=webp' : src.substr(0, pos < 0 ? src.length : pos) + '.webp'
// 	return image
// }

// export const isMobile = () => {
// 	return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
// }

// export const isWebPSupport = () => {
// 	const elem = typeof document === 'object' ? document.createElement('canvas') : {}
// 	if (!!(elem.getContext && elem.getContext('2d'))) {
// 		return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0
// 	}
// 	return false
// }

// export const queryStringToObject = (url) => {
// 	return _.chain(url).replace('?', '').split('&').map(_.partial(_.split, _, '=', 2)).fromPairs().value()
// }

// export const objectToQueryString = (obj) => {
// 	if (obj) {
// 		const results = []
// 		_.forOwn(obj, (value, key) => {
// 			if (Array.isArray(value)) {
// 				_.forOwn(value, (value) => {
// 					results.push(`${key}=${value}`)
// 				})
// 			} else {
// 				results.push(`${key}=${value}`)
// 			}
// 		})
// 		return results.join('&')
// 	}
// 	return ''
// }

// export const formatNumber = (x, lang) => {
// 	let str = x && (typeof x === 'string' ? x : x.toString())

// 	if (!x) {
// 		return 0
// 	} else {
// 		str = str.replace(',', '')
// 		str = str.replace(/\s/g, '')
// 		str = new Intl.NumberFormat('de-DE').format(str)
// 		return str
// 	}
// }

//! generate random number between two numbers
// export const randomIntFromInterval = (min, max) => {
// 	return Math.floor(Math.random() * (max - min + 1) + min)
// }

export const setCookie = (key, cookie) => {
    Cookie.set(key, cookie)
}

export const removeCookie = (key) => {
    Cookie.remove(key)
}

export const asyncFunctionDecorator = async (asyncFunc) => {
    return await asyncFunc().catch((err) => {
        return Promise.reject(err.response)
    })
}

export const getRequestCookies = (ctx, key) => {
    return ctx?.req?.cookies?.[key]
}

export const getCookie = (key) => {
    return Cookie.get(key)
}

export const letterSpacingAnimation = (refs) => {
    return gsap.fromTo(refs, { letterSpacing: '1.8rem', opacity: 0 }, { duration: 1.5, ease: 'power3.out', autoRound: false, letterSpacing: '1px', opacity: 1 })
}
