export default {
	colors: {
		//! Main
		maastrichtBlue: '#0C1D31',
		oxfordBlue: '#3C4A5A',
		paleSk: '#6D7783',
		grayChateau: '#9DA4AC',
		bombay: '#B5BAC0',
		iron: '#CED1D5',
		porcelain: '#E6E8EA',
		whiteBlue: '#F2F3F4',
		twine: '#BF9D66',
		sorrellBrown: '#CBB084',
		pavlova: '#D8C4A3',
		bone: '#E5D8C2',
		athsSpecia: '#ECE1D1',
		ecruWhite: '#F2EBE0',
		merino: '#F9F5EF',
		vistaWhite: '#FCFAF7',

		//! Secondary
		barleyCorn: '#9A835B',
		goBen: '#776A51',
		fuscousGray: '#535046',
		lunarGree: '#424341',
		outerSpace: '#30363B',
		ebonyClay: '#1F2A36',
		bigStone: '#152434',
		whiteLinen: '#F0EBE3',

		//! Success / error
		niagara: '#08B37A',
		trinidad: '#F23E08',
		white: '#FFFFFF',

		//! Body
		alabaste: '#F9F9F9',
	},

	mediaQuery: {
		DesktopSizeXL: '2560px',
		DesktopSizeL: '1920px',
		DesktopSizeM: '1510px',
		DesktopSizeS: '1440px',
		DesktopSizeXS: '1280px',
		TabletSize: '1024px',
		TabletSizeS: '768px',
		MobileSize: '601px',
		MobileSizeS: '414px',
		MobileSizeSMin: '400px',

		DesktopSizeXLMin: '2559px',
		DesktopSizeLMin: '1919px',
		DesktopSizeMMin: '1509px',
		DesktopSizeSMin: '1439px',
		DesktopSizeXSMin: '1279px',
		TabletSizeMin: '1023px',
		TabletSizeSMin: '767px',
		MobileSizeMin: '600px',

		//! Ipad
		ipadRotateLargeSize: '1366px',
		ipadRotateNormalSize: '1112px',
		ipadRotateSmallSize: '834px',
	},
}
