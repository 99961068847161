import api from './_api'
import config from './_config'
import getContext from './_getContext'
import formValidation from './_formValidation'
import { getData, getPageParams } from './_getData'
import { objectToFormData } from './_objectToFormData'
import { getParamsByKey } from './_getParamsByKey'
import { routerToPathAndQuery } from './_routerToPathAndQuery'
import { useActiveSection } from './_useActiveSection'

import {
    // queryStringToObject,
    // objectToQueryString,
    // imagePathToWebP,
    // isWebPSupport,
    // arrayGen,
    // arrayGenChild,
    // formatNumber,
    // randomIntFromInterval,
    asyncFunctionDecorator,
    getRequestCookies,
    getCookie,
    setCookie,
    removeCookie,
    letterSpacingAnimation
} from './_functions'

export {
    api,
    config,
    // objectToQueryString,
    // imagePathToWebP,
    // isWebPSupport,
    // arrayGen,
    // arrayGenChild,
    // formatNumber,
    // randomIntFromInterval,
    // queryStringToObject,
    getContext,
    formValidation,
    getData,
    getPageParams,
    objectToFormData,
    getParamsByKey,
    routerToPathAndQuery,
    asyncFunctionDecorator,
    getRequestCookies,
    getCookie,
    setCookie,
    removeCookie,
    letterSpacingAnimation,
    useActiveSection
}
