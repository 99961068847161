import axios from 'axios'
import Cookie from 'js-cookie'

class Api {
	requester = axios.create()

	constructor() {
		this.requester.interceptors.request.use((request) => {
			request.baseURL = process.env.REACT_APP_API_SERVER

			if (Cookie.get('lang')) {
				request.headers['Content-Language'] = Cookie.get('lang')
			}

			if (Cookie.get('accessToken')) {
				request.headers.Authorization = `Bearer ${Cookie.get('accessToken')}`
			}

			return request
		})

		this.requester.interceptors.response.use(
			(response) => {
				return response
			},
			(error) => {
				return Promise.reject(error)
			}
		)
	}

	async get(resource, lang, params = {}, accessToken) {
		params = new Map(Object.entries(params))

		const options = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Language': lang,
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		}

		accessToken && (options.headers.Authorization = `Bearer ${accessToken}`)

		const response = await this.request(`${resource}?${Api.serializeParams(params)}`, options)

		return response.data
	}

	async post(resource, params = {}, lang = undefined, contentType) {
		const options = {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Language': lang,
				'Content-Type': contentType || 'application/json',
			},
			data: params,
		}

		lang && (options.headers['Content-Language'] = lang)

		const response = await this.request(resource, options)

		return response.data
	}

	async put(resource, params = {}, lang, contentType) {
		const options = {
			method: 'PUT',
			headers: {
				Accept: 'application/json',
				'Content-Language': lang,
				'Content-Type': contentType || 'application/json',
			},
			data: params,
		}

		lang && (options.headers['Content-Language'] = lang)

		const response = await this.request(resource, options)

		return response.data
	}

	async delete(resource, params = {}) {
		const options = {
			method: 'DELETE',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			data: { ...params },
		}

		const response = await this.request(resource, options)

		return response.data
	}

	async request(url, options) {
		return this.requester(url, options)
	}

	static serializeParams(params) {
		const array = []

		params.forEach((value, key) => {
			array.push(`${key}=${encodeURIComponent(value)}`)
		})

		return array.join('&')
	}
}

export default new Api()
