import styled from 'styled-components';

const HeaderStyle = styled.header`
	--headerContentPadTB: var(--sp2x);
	--navBarItemDistance: var(--sp11x);
	--logoDistance: var(--sp13x);
	--burgerMenuIconSize: var(--sp5x);
	--burgerMenuSizes: var(--sp5x);

	--additionalVariable: 1.3%;

	position: fixed;
	width: 100%;
	top: -100%;
	transition: top 0.5s;
	z-index: 7;

	&.visible {
		top: 0;
	}

	.header-content-wrap {
		display: flex;
		align-items: center;
		padding: var(--headerContentPadTB) 0;

		.hc-col-wrap,
		.hc-col-wrap {
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex: 1;

			.icon-wrapper {
				i {
					line-height: 1 !important;

					&:before {
						color: var(--primaryColor9);
					}

					@media (hover: hover) {
						&:hover {
							&:before {
								color: var(--secondaryColor2);
							}
						}
					}
				}
			}

			.burger-menu-wrapper {
				width: var(--burgerMenuIconSize);

				.lottie-wrapper {
					height: var(--burgerMenuIconSize);

					svg {
						display: block;
						width: var(--burgerMenuSizes) !important;
						height: var(--burgerMenuSizes);
					}
				}
			}

			.navbar-menu-wrap {
				display: flex;
				align-items: center;

				.navbar-menu-item {
					margin: 0 calc(var(--navBarItemDistance) / 2);
				}

				&.left {
					.navbar-menu-item {
						&:last-child {
							margin-right: 0;
						}
					}
				}

				&.right {
					.navbar-menu-item {
						&:first-child {
							margin-left: 0;
						}
					}
				}
			}
		}

		.logo-wrapper {
			background-color: transparent !important;

			width: var(--logoWidth);
			margin: 0 var(--logoDistance);

			.image-cont {
				padding-top: var(--logoHeight);
			}
		}
	}

	&.transparent-header {
		.header-container {
			.header-fake-background {
				opacity: 0;
			}
		}
	}

	.header-container {
		.header-fake-background {
			position: absolute;
			inset: 0;
			background-color: var(--bodyColor);
			z-index: -1;
			transform: translate3d(0, 0, 0);
			transition: transform var(--trTime);

			&.active {
				transform: translate3d(-100%, 0, 0);
			}
		}
	}

	.language-switcher-wrapper {
		position: absolute;
		top: 0;
		right: var(--contPaddingLR);
		margin-top: calc(var(--headerContentPadTB) + var(--additionalVariable));
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--headerContentPadTB: var(--sp2x);
		--navBarItemDistance: var(--sp8x);
		--logoDistance: var(--sp10x);

		--additionalVariable: 1.4%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--headerContentPadTB: var(--sp1x);
		--navBarItemDistance: unset;
		--logoDistance: unset;

		--additionalVariable: 1.05%;

		.navbar-menu-wrap {
			display: none !important;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--headerContentPadTB: var(--sp1x);
		--navBarItemDistance: unset;
		--logoDistance: unset;

		--additionalVariable: 1.05%;

		.navbar-menu-wrap {
			display: none !important;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--headerContentPadTB: var(--sp1x);
		--navBarItemDistance: unset;
		--logoDistance: unset;

		--additionalVariable: 1.2%;

		.navbar-menu-wrap {
			display: none !important;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--headerContentPadTB: var(--sp1-5x);
		--navBarItemDistance: unset;
		--logoDistance: unset;

		--additionalVariable: 0.7%;

		.navbar-menu-wrap {
			display: none !important;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--headerContentPadTB: var(--sp1-5x);
		--navBarItemDistance: unset;
		--logoDistance: unset;

		--additionalVariable: 1.05%;

		.navbar-menu-wrap {
			display: none !important;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--headerContentPadTB: var(--sp1-5x);
		--navBarItemDistance: unset;
		--logoDistance: unset;

		--additionalVariable: 4.4%;

		--burgerMenuIconSize: var(--sp4x);
		--burgerMenuSizes: var(--sp4x);

		.navbar-menu-wrap {
			display: none !important;
		}
	}
`;

export default HeaderStyle;
