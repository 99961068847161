import { createGlobalStyle } from 'styled-components'
import localFont from '@next/font/local'

const minion3 = localFont({
	src: [
		{
			path: '../public/fonts/Minion3-Subhead-Regular.woff2',
			weight: '400',
			display: 'swap',
			style: 'normal',
			variable: '--minion3Font',
		},
		{
			path: '../public/fonts/Minion3-Subhead-Medium.woff2',
			weight: '500',
			display: 'swap',
			style: 'normal',
			variable: '--minion3Font',
		},
		{
			path: '../public/fonts/Minion3-Subhead-Bold.woff2',
			weight: '600',
			style: 'normal',
			display: 'swap',
			variable: '--minion3Font',
		},
		{
			path: '../public/fonts/Minion3-Subhead-Semibold.woff2',
			weight: '700',
			display: 'swap',
			style: 'normal',
			variable: '--minion3Font',
		},
	],
})

const notoSans = localFont({
	src: [
		{
			path: '../public/fonts/NotoSans-Armenian-Regular.woff2',
			weight: '400',
			display: 'swap',
			style: 'normal',
			variable: '--notoSansFont',
		},
		{
			path: '../public/fonts/NotoSans-Armenian-Medium.woff2',
			weight: '500',
			display: 'swap',
			style: 'normal',
			variable: '--notoSansFont',
		},
		{
			path: '../public/fonts/NotoSans-Armenian-SemiBold.woff2',
			weight: '700',
			display: 'swap',
			style: 'normal',
			variable: '--notoSansFont',
		},
	],
})

function spaceGenerator() {
	let str = ''

	for (let i = 1; i <= 250; i++) {
		str += `--sp${i}x: calc(var(--spSize) * ${i});`
	}

	return str
}

const Variables = createGlobalStyle`
    :root {
        //! Fonts
        --minion3Font: ${minion3.style.fontFamily};
        --notoSansFont: ${notoSans.style.fontFamily};

        //! Screen Sizes
        --desktopSizeXL:${(props) => props.theme.mediaQuery.DesktopSizeXL};
        --desktopSizeL:${(props) => props.theme.mediaQuery.DesktopSizeL};
        --desktopSizeM:${(props) => props.theme.mediaQuery.DesktopSizeM};
        --desktopSizeS: ${(props) => props.theme.mediaQuery.DesktopSizeS};
        --desktopSizeXS: ${(props) => props.theme.mediaQuery.DesktopSizeXS};
        --tabletSize: ${(props) => props.theme.mediaQuery.TabletSize};
        --tabletSizeS: ${(props) => props.theme.mediaQuery.TabletSizeS};
        --mobileSize: ${(props) => props.theme.mediaQuery.MobileSize};
        --mobileSizeS: ${(props) => props.theme.mediaQuery.MobileSizeS};

        //! Spacing
        --spSize: 8px;

        --sp0-5x: calc(var(--spSize) * 0.5);
        --sp1-5x: calc(var(--spSize) * 1.5);
        --sp2-5x: calc(var(--spSize) * 2.5);
        ${spaceGenerator()}

        //! Transition
        --trTime: 0.3s;

        //! Line heights
        --lineHeightL: 1.5;
        --lineHeightM: 1.2;
        --lineHeightS: 1.1;

        //! Colors
            //! Primary
            --primaryColor1: ${(props) => props.theme.colors.maastrichtBlue};
            --primaryColor2: ${(props) => props.theme.colors.oxfordBlue};
            --primaryColor3: ${(props) => props.theme.colors.paleSk};
            --primaryColor4: ${(props) => props.theme.colors.grayChateau};
            --primaryColor5: ${(props) => props.theme.colors.bombay};
            --primaryColor6: ${(props) => props.theme.colors.iron};
            --primaryColor7: ${(props) => props.theme.colors.porcelain};
            --primaryColor8: ${(props) => props.theme.colors.whiteBlue};
            --primaryColor9: ${(props) => props.theme.colors.twine};
            --primaryColor10: ${(props) => props.theme.colors.sorrellBrown};
            --primaryColor11: ${(props) => props.theme.colors.pavlova};
            --primaryColor12: ${(props) => props.theme.colors.bone};
            --primaryColor13: ${(props) => props.theme.colors.athsSpecia};
            --primaryColor14: ${(props) => props.theme.colors.ecruWhite};
            --primaryColor15: ${(props) => props.theme.colors.merino};
            --primaryColor16: ${(props) => props.theme.colors.vistaWhite};

            //! Secondary
            --secondaryColor1: ${(props) => props.theme.colors.barleyCorn};
            --secondaryColor2: ${(props) => props.theme.colors.goBen};
            --secondaryColor3: ${(props) => props.theme.colors.fuscousGray};
            --secondaryColor4: ${(props) => props.theme.colors.lunarGree};
            --secondaryColor5: ${(props) => props.theme.colors.outerSpace};
            --secondaryColor6: ${(props) => props.theme.colors.ebonyClay};
            --secondaryColor7: ${(props) => props.theme.colors.bigStone};
            --secondaryColor8: ${(props) => props.theme.colors.whiteLinen};
            
            //! Success / error
            --successColor: ${(props) => props.theme.colors.niagara};
            --errorColor: ${(props) => props.theme.colors.trinidad};

            //! Product card color
            --white: ${(props) => props.theme.colors.white};

            //! Body
            --bodyColor: ${(props) => props.theme.colors.alabaste};
    }

    @media only screen and  (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXL}) {
        :root {
            --h1: 440px;
            --h2: 176px;
            --h3: 128px;
            --h4: 96px;
            --h5: 80px;
            --h6: 56px;
            --p1: 40px;
            --p2: 32px;
            --p3: 24px;
            --p4: 20px;
            --p5: 16px;

            //! Line heights
            --lineHeightH1: var(--lineHeightS);
            --lineHeightH2: var(--lineHeightS);
            --lineHeightH3: var(--lineHeightS);
            --lineHeightH4: var(--lineHeightM);
            --lineHeightH5: var(--lineHeightM); 
            --lineHeightH6: var(--lineHeightM);
            --lineHeightP1: var(--lineHeightM);
            --lineHeightP2: var(--lineHeightL);
            --lineHeightP3: var(--lineHeightL);
            --lineHeightP4: var(--lineHeightL);
            --lineHeightP5: var(--lineHeightL);

            //! Section distance
            --sectionDistance: var(--sp25x);

            //! Sub section distance
            --subSectionDistance: var(--sp16x);

            //! Container padding left & right 
            --contPaddingLR: var(--sp17x);

            //! Grid distance
            --colPadding: var(--sp5x);

            //! Border radius size
            --borderRadiusSize: var(--sp3x);

            //! Round button (cursor)
            --roundButtonCursorSize: var(--sp25x);

            //! Play & pause (cursor)
            --playPauseCursorSize: var(--sp24x);

            //! Logo size's
            --logoWidth: var(--sp21x);
            --logoHeight: var(--sp13x);
        }
    }

    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
        :root {
            --h1: 320px;
            --h2: 144px;
            --h3: 96px;
            --h4: 80px;
            --h5: 56px;
            --h6: 48px;
            --p1: 32px;
            --p2: 24px;
            --p3: 20px;
            --p4: 16px;
            --p5: 14px;

            //! Line heights
            --lineHeightH1: var(--lineHeightS);
            --lineHeightH2: var(--lineHeightS);
            --lineHeightH3: var(--lineHeightS);
            --lineHeightH4: var(--lineHeightM);
            --lineHeightH5: var(--lineHeightM); 
            --lineHeightH6: var(--lineHeightM);
            --lineHeightP1: var(--lineHeightL);
            --lineHeightP2: var(--lineHeightL);
            --lineHeightP3: var(--lineHeightL);
            --lineHeightP4: var(--lineHeightL);
            --lineHeightP5: var(--lineHeightL);

            //! Section distance
            --sectionDistance: var(--sp21x);

            //! Sub section distance
            --subSectionDistance: var(--sp13x);

            //! Container padding left & right 
            --contPaddingLR: var(--sp13x);

            //! Grid distance
            --colPadding: var(--sp5x);
            
            //! Border radius size
            --borderRadiusSize: var(--sp3x);
            
            //! Round button (cursor)
            --roundButtonCursorSize: var(--sp18x);

            //! Play & pause (cursor)
            --playPauseCursorSize: var(--sp19x);

            //! Logo size's
            --logoWidth: var(--sp17x);
            --logoHeight: var(--sp11x);
        }
    }

    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
        :root {
            --h1: 240px;
            --h2: 112px;
            --h3: 80px;
            --h4: 72px;
            --h5: 48px;
            --h6: 40px;
            --p1: 24px;
            --p2: 20px;
            --p3: 18px;
            --p4: 16px;
            --p5: 14px;

            //! Line heights
            --lineHeightH1: var(--lineHeightS);
            --lineHeightH2: var(--lineHeightS);
            --lineHeightH3: var(--lineHeightM);
            --lineHeightH4: var(--lineHeightM);
            --lineHeightH5: var(--lineHeightM); 
            --lineHeightH6: var(--lineHeightM);
            --lineHeightP1: var(--lineHeightL);
            --lineHeightP2: var(--lineHeightL);
            --lineHeightP3: var(--lineHeightL);
            --lineHeightP4: var(--lineHeightL);
            --lineHeightP5: var(--lineHeightL);
            
            //! Section distance
            --sectionDistance: var(--sp18x);

            //! Sub section distance
            --subSectionDistance: var(--sp10x);

            //! Container padding left & right 
            --contPaddingLR: var(--sp11x);

            //! Grid distance
            --colPadding: var(--sp5x);
            
            //! Border radius size
            --borderRadiusSize: var(--sp3x);
            
            //! Round button (cursor)
            --roundButtonCursorSize: var(--sp16x);

            //! Play & pause (cursor)
            --playPauseCursorSize: var(--sp16x);

            //! Logo size's
            --logoWidth: var(--sp13x);
            --logoHeight: var(--sp8x);
        }
    }

    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
        :root {
            --h1: 232px;
            --h2: 104px;
            --h3: 72px;
            --h4: 64px;
            --h5: 40px;
            --h6: 32px;
            --p1: 24px;
            --p2: 20px;
            --p3: 18px;
            --p4: 16px;
            --p5: 14px;

            //! Line heights
            --lineHeightH1: var(--lineHeightS);
            --lineHeightH2: var(--lineHeightS);
            --lineHeightH3: var(--lineHeightM);
            --lineHeightH4: var(--lineHeightM);
            --lineHeightH5: var(--lineHeightM); 
            --lineHeightH6: var(--lineHeightL);
            --lineHeightP1: var(--lineHeightL);
            --lineHeightP2: var(--lineHeightL);
            --lineHeightP3: var(--lineHeightL);
            --lineHeightP4: var(--lineHeightL);
            --lineHeightP5: var(--lineHeightL);
                        
            //! Section distance
            --sectionDistance: var(--sp17x);

            //! Sub section distance
            --subSectionDistance: var(--sp9x);

            //! Container padding left & right 
            --contPaddingLR: var(--sp8x);

            //! Grid distance
            --colPadding: var(--sp4x);

            //! Border radius size
            --borderRadiusSize: var(--sp3x);
            
            //! Round button (cursor)
            --roundButtonCursorSize: var(--sp16x);

            //! Play & pause (cursor)
            --playPauseCursorSize: var(--sp15x);

            //! Logo size's
            --logoWidth: var(--sp13x);
            --logoHeight: var(--sp8x);
        }
    }

    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
        :root {
            --h1: 208px;
            --h2: 80px;
            --h3: 64px;
            --h4: 56px;
            --h5: 40px;
            --h6: 32px;
            --p1: 24px;
            --p2: 20px;
            --p3: 16px;
            --p4: 16px;
            --p5: 14px;

            //! Line heights
            --lineHeightH1: var(--lineHeightS);
            --lineHeightH2: var(--lineHeightM);
            --lineHeightH3: var(--lineHeightM);
            --lineHeightH4: var(--lineHeightM);
            --lineHeightH5: var(--lineHeightM); 
            --lineHeightH6: var(--lineHeightL);
            --lineHeightP1: var(--lineHeightL);
            --lineHeightP2: var(--lineHeightL);
            --lineHeightP3: var(--lineHeightL);
            --lineHeightP4: var(--lineHeightL);
            --lineHeightP5: var(--lineHeightL);
                        
            //! Section distance
            --sectionDistance: var(--sp16x);

            //! Sub section distance
            --subSectionDistance: var(--sp8x);

            //! Container padding left & right 
            --contPaddingLR: var(--sp7x);

            //! Grid distance
            --colPadding: var(--sp4x);
            
            //! Border radius size
            --borderRadiusSize: var(--sp3x);
            
            //! Round button (cursor)
            --roundButtonCursorSize: var(--sp14x);

            //! Play & pause (cursor)
            --playPauseCursorSize: var(--sp13x);

            //! Logo size's
            --logoWidth: var(--sp13x);
            --logoHeight: var(--sp8x);
        }
    }

    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
        :root {
            --h1: 160px;
            --h2: 64px;
            --h3: 56px;
            --h4: 48px;
            --h5: 40px;
            --h6: 32px;
            --p1: 20px;
            --p2: 20px;
            --p3: 16px;
            --p4: 16px;
            --p5: 14px;

            //! Line heights
            --lineHeightH1: var(--lineHeightS);
            --lineHeightH2: var(--lineHeightM);
            --lineHeightH3: var(--lineHeightM);
            --lineHeightH4: var(--lineHeightM);
            --lineHeightH5: var(--lineHeightM); 
            --lineHeightH6: var(--lineHeightL);
            --lineHeightP1: var(--lineHeightL);
            --lineHeightP2: var(--lineHeightL);
            --lineHeightP3: var(--lineHeightL);
            --lineHeightP4: var(--lineHeightL);
            --lineHeightP5: var(--lineHeightL);
                        
            //! Section distance
            --sectionDistance: var(--sp14x);

            //! Sub section distance
            --subSectionDistance: var(--sp7x);

            //! Container padding left & right 
            --contPaddingLR: var(--sp6x);

            //! Grid distance
            --colPadding: var(--sp4x);

            //! Border radius size
            --borderRadiusSize: var(--sp2x);
            
            //! Round button (cursor)
            --roundButtonCursorSize: var(--sp14x);

            //! Play & pause (cursor)
            --playPauseCursorSize: var(--sp11x);

            //! Logo size's
            --logoWidth: var(--sp10x);
            --logoHeight: var(--sp6x);
        }
    }

    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
        :root {
            --h1: 160px;
            --h2: 64px;
            --h3: 56px;
            --h4: 48px;
            --h5: 40px;
            --h6: 32px;
            --p1: 20px;
            --p2: 20px;
            --p3: 16px;
            --p4: 16px;
            --p5: 14px;

            //! Line heights
            --lineHeightH1: var(--lineHeightS);
            --lineHeightH2: var(--lineHeightM);
            --lineHeightH3: var(--lineHeightM);
            --lineHeightH4: var(--lineHeightM);
            --lineHeightH5: var(--lineHeightM); 
            --lineHeightH6: var(--lineHeightL);
            --lineHeightP1: var(--lineHeightL);
            --lineHeightP2: var(--lineHeightL);
            --lineHeightP3: var(--lineHeightL);
            --lineHeightP4: var(--lineHeightL);
            --lineHeightP5: var(--lineHeightL);
                        
            //! Section distance
            --sectionDistance: var(--sp14x);

            //! Sub section distance
            --subSectionDistance: var(--sp7x);

            //! Container padding left & right 
            --contPaddingLR: var(--sp6x);

            //! Grid distance
            --colPadding: var(--sp3x);
            
            //! Border radius size
            --borderRadiusSize: var(--sp2x);
            
            //! Round button (cursor)
            --roundButtonCursorSize: var(--sp14x);

            //! Play & pause (cursor)
            --playPauseCursorSize: var(--sp11x);

            //! Logo size's
            --logoWidth: var(--sp10x);
            --logoHeight: var(--sp6x);
        }
    }

    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
        :root {
            --h1: 128px;
            --h2: 48px;
            --h3: 36px;
            --h4: 32px;
            --h5: 28px;
            --h6: 24px;
            --p1: 20px;
            --p2: 20px;
            --p3: 16px;
            --p4: 16px;
            --p5: 14px;

            //! Line heights
            --lineHeightH1: var(--lineHeightS);
            --lineHeightH2: var(--lineHeightM);
            --lineHeightH3: var(--lineHeightL);
            --lineHeightH4: var(--lineHeightL);
            --lineHeightH5: var(--lineHeightL); 
            --lineHeightH6: var(--lineHeightL);
            --lineHeightP1: var(--lineHeightL);
            --lineHeightP2: var(--lineHeightL);
            --lineHeightP3: var(--lineHeightL);
            --lineHeightP4: var(--lineHeightL);
            --lineHeightP5: var(--lineHeightL);
                        
            //! Section distance
            --sectionDistance: var(--sp10x);

            //! Sub section distance
            --subSectionDistance: var(--sp5x);

            //! Container padding left & right 
            --contPaddingLR: var(--sp3x);

            //! Grid distance
            --colPadding: var(--sp2x);
            
            //! Border radius size
            --borderRadiusSize: var(--sp2x);

            //! Round button (cursor)
            --roundButtonCursorSize: var(--sp12x);

            //! Play & pause (cursor)
            --playPauseCursorSize: var(--sp8x);

            //! Logo size's
            --logoWidth: var(--sp13x);
            --logoHeight: var(--sp8x);
        }
    }
`

export default Variables
