export default {
	routes: {
		home: {
			path: '/',
			slug: 'home',
			name: 'home',
			autoload: true,
			inBurgerMenu: true,
		},

		aboutUs: {
			path: '/about-us',
			slug: 'about-us',
			name: 'aboutUs',
			autoload: true,
			inHeader: 'left',
			inFooter: true,
			inBurgerMenu: true,
			transparentHeader: true,
		},

		products: {
			path: '/products',
			slug: 'products',
			name: 'products',
			autoload: true,
			inHeader: 'left',
			inFooter: true,
			inBurgerMenu: true,
		},

		singleProducts: {
			path: '/products/:slug',
			slug: 'products',
			name: 'singleProducts',
			singleType: 'singleProducts',
			autoload: true,
			inHeader: false,
			inFooter: false,
			inBurgerMenu: false,
			transparentHeader: true,
		},

		singleCollections: {
			path: '/products/collection/:slug',
			slug: 'products/collection',
			name: 'singleCollections',
			singleType: 'singleCollections',
			autoload: true,
			inHeader: false,
			inFooter: false,
			inBurgerMenu: false,
		},

		cocktails: {
			path: '/cocktails',
			slug: 'cocktails',
			name: 'cocktails',
			autoload: true,
			inHeader: 'left',
			inFooter: true,
			inBurgerMenu: true,
		},

		singleCocktail: {
			path: '/cocktails/:slug',
			slug: 'cocktails',
			name: 'singleCocktail',
			singleType: 'singleCocktail',
			autoload: true,
			inHeader: false,
			inFooter: false,
			inBurgerMenu: false,
			transparentHeader: true,
		},

		newsAndEvents: {
			path: '/news-and-events',
			slug: 'event-news',
			name: 'newsAndEvents',
			autoload: true,
			inHeader: 'right',
			inFooter: true,
			inBurgerMenu: true,
		},

		singleNewsAndEvents: {
			path: '/news-and-events/:slug',
			slug: 'event-news',
			name: 'singleNewsAndEvents',
			singleType: 'singleNewsAndEvents',
			autoload: true,
			inHeader: false,
			inFooter: false,
			inBurgerMenu: false,
			transparentHeader: true,
		},

		distributors: {
			path: '/distributors',
			slug: 'distributors',
			name: 'distributors',
			autoload: true,
			inHeader: 'right',
			inFooter: true,
			inBurgerMenu: true,
		},

		contacts: {
			path: '/contacts',
			slug: 'contact-us',
			name: 'contacts',
			autoload: true,
			inHeader: 'right',
			inFooter: true,
			inBurgerMenu: true,
			transparentHeader: true,
		},
	},
};
