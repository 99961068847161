//* Providers
import UIProvider from './providers/UIProvider'
import DataProvider from './providers/DataProvider'
import LanguageProvider from './providers/LanguageProvider'

//* ConsumerHOCs
import withUIContext from './consumerHOC/UIConsumer'
import withDataContext from './consumerHOC/DataConsumer'
import withLanguageContext from './consumerHOC/LanguageConsumer'

export { UIProvider, withUIContext, DataProvider, withDataContext, LanguageProvider, withLanguageContext }
