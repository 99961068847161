import { useRef, useImperativeHandle, forwardRef } from 'react'

//* Style
import ContainerStyle from './style'

const Container = forwardRef(({ row, first, section, full, className, ...props }, ref) => {
	const contRef = useRef()

	useImperativeHandle(ref, () => contRef.current, [])

	return (
		<ContainerStyle
			ref={contRef}
			onMouseMove={props.onMouseMove}
			onMouseLeave={props.onMouseLeave}
			className={`container ${className || ''} ${section ? 'section' : ''} ${full ? 'full' : ''} ${first ? 'first-section' : ''}`}
		>
			{row ? <div className='row'>{props.children}</div> : props.children}
		</ContainerStyle>
	)
})

Container.displayName = 'Container'

export default Container
