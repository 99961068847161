import { forwardRef, useRef, useImperativeHandle, useState } from 'react';
import Image from 'next/image';

//* Style
import ImageStyle from './style';

const CustomImage = forwardRef(({ src, alt, className, width, height, priority = false, onLoad }, ref) => {
	//! Ref
	const imgRef = useRef();

	//! State
	const [loaded, setLoaded] = useState(false);

	useImperativeHandle(ref, () => imgRef.current, []);

	//! Handle Image Load
	const onLoadCallback = () => {
		setLoaded(true);

		typeof onLoad === 'function' && onLoad(loaded);
	};

	return (
		<ImageStyle
			ref={imgRef}
			className={`image-cont ${className || ``}`}
			style={{ width, height, backgroundColor: loaded ? 'transparent' : '#F4EFE7' }}>
			<Image
				fill
				draggable={false}
				className='image'
				placeholder='blur'
				priority={priority}
				alt={alt || `image`}
				sizes='(max-width: 767px) 100%'
				onLoadingComplete={onLoadCallback}
				style={{ opacity: loaded ? 1 : 0 }}
				src={src || `/images/default.jpeg`}
				loading={priority ? 'eager' : 'lazy'}
				unoptimized={src && src.includes(`.svg`)}
				blurDataURL={src || '/images/default.png'}
			/>
		</ImageStyle>
	);
});

CustomImage.displayName = `CustomImage`;

export default CustomImage;
