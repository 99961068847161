import styled from 'styled-components';

const ImageStyle = styled.div`
	--proportion: 50%;

	&.image-cont {
		position: relative;
		width: 100%;
		padding-top: var(--proportion);
		transition: background-color 0.3s ease-out;

		img {
			object-fit: cover;
			object-position: center;
			transition: all 0.6s ease-out;
			z-index: 1;
		}

		&.contain {
			img {
				object-fit: contain;
			}
		}
	}
`;
export default ImageStyle;
