import styled from 'styled-components'

const LanguageSwitcherStyle = styled.div`
	--selectedLangPadTB: var(--sp1x);
	--selectedLangPadL: var(--sp2x);
	--selectedLangR: var(--sp2x);
	--iconSize: var(--sp1-5x);
	--langContentPad: var(--sp1-5x);
	--langContMTop: var(--sp1-5x);
	--iconTransformXSize: calc(var(--sp1x) / 2);
	--iconTransformYSize: calc(var(--sp1x) / 5);

	.selected-lang-wrapper {
		display: flex;
		align-items: center;
		border: 1px solid var(--primaryColor9);
		padding: var(--selectedLangPadTB) var(--selectedLangR) var(--selectedLangPadTB) var(--selectedLangPadL);
		border-radius: calc(var(--sp15x) / 2);
		cursor: pointer;

		i {
			pointer-events: none;
			line-height: 1 !important;
			margin-left: var(--sp1x);
			font-size: var(--iconSize);
			transform: rotate(0);
			transition: transform var(--trTime);

			&:before {
				color: var(--primaryColor9);
			}
		}
	}

	.languages-cont {
		overflow: hidden;
		height: 0;

		.body-container {
			width: 100%;
			display: flex;
			flex-direction: column;
			border: 1px solid var(--primaryColor9);
			border-radius: var(--sp1x);
			margin-top: var(--langContMTop);
			padding: var(--langContentPad);

			.lang-item {
				&:not(:first-child) {
					margin-top: calc(var(--sp1x) / 2);
				}

				@media (hover: hover) {
					&:hover {
						color: var(--secondaryColor2);
					}
				}
			}
		}
	}

	&.opened {
		.selected-lang-wrapper {
			i {
				&.rotate-icon {
					transform: rotate(-180deg);
					transition: transform var(--trTime);
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) {
		--iconSize: calc(var(--sp2-5x) / 2);
		--selectedLangR: var(--sp1-5x);
		--langContentPad: var(--sp1x);
		--langContMTop: var(--sp1x);

		--selectedLangPadTB: var(--sp0-5x);
		--selectedLangPadL: var(--sp1x);
	}
`

export default LanguageSwitcherStyle
