//* Components
import Text from 'components/common/Text'
import Logo from 'components/common/Logo'

//* Style
import AgeGateRejectionStyle from './style'

const AgeGateRejection = () => {
    return (
        <AgeGateRejectionStyle>
            <Logo className={'age-gate-logo'} />
            <div className='content-wrap'>
                <Text
                    className={'h5 minion3-medium primary-color9 uppercase title'}
                    text={'ageGateRejectionTitle'}
                />
                <Text
                    className={'p2 notoSans-regular primary-color9'}
                    text={'ageGateRejectionDescription'}
                />
            </div>
        </AgeGateRejectionStyle>
    )
}

export default AgeGateRejection
