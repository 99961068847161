import { createGlobalStyle } from 'styled-components'

const HelperClass = createGlobalStyle`
    //! Fonts
    //! Minion3 Subhead
    .minion3-regular {
        font-family: var(--minion3Font);
        font-weight: 400;
    }

    .minion3-medium {
        font-family: var(--minion3Font);
        font-weight: 500;
    }

    .minion3-bold {
        font-family: var(--minion3Font);
        font-weight: 600;
    }

    .minion3-semiBold {
        font-family: var(--minion3Font);
        font-weight: 700;
    }

    //! Noto Sans Armenian
    .notoSans-regular {
        font-family: var(--notoSansFont);
        font-weight: 400;
    }

    .notoSans-medium {
        font-family: var(--notoSansFont);
        font-weight: 500;
    }
    
    .notoSans-semiBold {
        font-family: var(--notoSansFont);
        font-weight: 700;
    }
        
    //! Text transforms
    .capitalize {
        text-transform: capitalize;
    }

    .uppercase {
        text-transform: uppercase;
    }

    .lowercase {
        text-transform: lowercase;
    }

    //! Text decorations
    .line-through {
        text-decoration: line-through;
    }

    .underline {
        text-decoration: underline;
    }

    .align-center {
        text-align: center;
    }

    //! Position relative
    .relative {
        position: relative;
    }

    //! No wrap
    .no-wrap {
        white-space: nowrap;
    }

    //! Cursor pointer
    .cursor-pointer {
        cursor: pointer;
    }

    //! Pointer none
    .pointer-none {
        pointer-events: none;
    }

    //! Opacity zero
    .opacity-zero {
        opacity: 0;
    }

    //! Display none
    .display-none {
        display: none;
    }

    //! Overflow hidden
    .overflow-hidden {
        overflow: hidden;
    }

    //! Primary colors 
    .primary-color1 {
        color: var(--primaryColor1);

        &-bg {
            background-color: var(--primaryColor1);
        }
    }

    .primary-color2 {
        color: var(--primaryColor2);

        &-bg {
            background-color: var(--primaryColor2);
        }
    }

    .primary-color3 {
        color: var(--primaryColor3);

        &-bg {
            background-color: var(--primaryColor3);
        }
    }

    .primary-color4 {
        color: var(--primaryColor4);

        &-bg {
            background-color: var(--primaryColor4);
        }
    }

    .primary-color5 {
        color: var(--primaryColor5);

        &-bg {
            background-color: var(--primaryColor5);
        }
    }

    .primary-color6 {
        color: var(--primaryColor6);

        &-bg {
            background-color: var(--primaryColor6);
        }
    }

    .primary-color7 {
        color: var(--primaryColor7);

        &-bg {
            background-color: var(--primaryColor7);
        }
    }

    .primary-color8 {
        color: var(--primaryColor8);

        &-bg {
            background-color: var(--primaryColor8);
        }
    }

    .primary-color9 {
        color: var(--primaryColor9);

        &-bg {
            background-color: var(--primaryColor9);
        }
    }

    .primary-color10 {
        color: var(--primaryColor10);

        &-bg {
            background-color: var(--primaryColor10);
        }
    }

    .primary-color11 {
        color: var(--primaryColor11);

        &-bg {
            background-color: var(--primaryColor11);
        }
    }

    .primary-color12 {
        color: var(--primaryColor12);

        &-bg {
            background-color: var(--primaryColor12);
        }
    }

    .primary-color13 {
        color: var(--primaryColor13);

        &-bg {
            background-color: var(--primaryColor13);
        }
    }

    .primary-color14 {
        color: var(--primaryColor14);

        &-bg {
            background-color: var(--primaryColor14);
        }
    }

    .primary-color15 {
        color: var(--primaryColor15);

        &-bg {
            background-color: var(--primaryColor15);
        }
    }

    .primary-color16 {
        color: var(--primaryColor16);

        &-bg {
            background-color: var(--primaryColor16);
        }
    }

    //! Secondary colors 
    .secondary-color1 {
        color: var(--secondaryColor1);

        &-bg {
            background-color: var(--secondaryColor1);
        }
    }

    .secondary-color2 {
        color: var(--secondaryColor2);

        &-bg {
            background-color: var(--secondaryColor2);
        }
    }

    .secondary-color3 {
        color: var(--secondaryColor3);

        &-bg {
            background-color: var(--secondaryColor3);
        }
    }

    .secondary-color4 {
        color: var(--secondaryColor4);

        &-bg {
            background-color: var(--secondaryColor4);
        }
    }

    .secondary-color5 {
        color: var(--secondaryColor5);

        &-bg {
            background-color: var(--secondaryColor5);
        }
    }

    .secondary-color6 {
        color: var(--secondaryColor6);

        &-bg {
            background-color: var(--secondaryColor6);
        }
    }

    .secondary-color7 {
        color: var(--secondaryColor7);

        &-bg {
            background-color: var(--secondaryColor7);
        }
    }

    .secondary-color8 {
        color: var(--secondaryColor8);

        &-bg {
            background-color: var(--secondaryColor8);
        }
    }

    //! Background color
    .body-color {
        color: var(--bodyColor);

        &-bg {
            background-color: var(--bodyColor);
        }
    }

    //! Success / error colors
    .success-color {
        color: var(--successColor);
    }
    
    .error-color {
        color: var(--errorColor);
    }

    .white {
        color: var(--white);

        &-bg {
            background-color: var(--white);
        }
    }
`

export default HelperClass
