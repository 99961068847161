import { Component, createContext } from 'react'
import { withRouter } from 'next/router'
import Cookies from 'js-cookie'
import xor from 'lodash.xor'
import template from 'lodash.template'
import isString from 'lodash.isstring'

import Languages from '../../translations'

const LanguageContext = createContext(null)

class LanguageProvider extends Component {
    state = {
        selectedLang: this.props.router.locale || Cookies.get('lang') || process.env.REACT_APP_DEFAULT_LANGUAGE,
        languages: Languages,
    }

    methods = {
        selectLanguage: this.selectLanguage.bind(this),
        translate: this.translate.bind(this),
    }

    getTwoLanguageDiffKeys(langKey1, langKey2) {
        console.log(xor(Object.keys(Languages[langKey1]), Object.keys(Languages[langKey2])))
    }

    componentDidMount() {
        this.selectLanguage(this.props.router.locale)
    }

    componentDidUpdate(prevProps) {
        if (this.props.router.locale !== prevProps.router.locale) {
            this.selectLanguage(this.props.router.locale)
        }
    }

    getLanguageParams(lang) {
        return this.state.languages[lang].params
    }

    selectLanguage(lang) {
        this.setState({ selectedLang: lang }, () => {
            Cookies.set('lang', lang)
        })
    }

    translate(val, vars) {
        const compiled = isString(val) && this.state.languages[this.state.selectedLang][val] && template(this.state.languages[this.state.selectedLang][val])
        return (compiled && compiled(vars)) || val || 'Please Set Translate Value'
    }

    render() {
        return <LanguageContext.Provider value={{ ...this.state, ...this.methods }}>{this.props.children}</LanguageContext.Provider>
    }
}

export default withRouter(LanguageProvider)
export const LanguageConsumer = LanguageContext.Consumer
